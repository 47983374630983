import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Link from "../components/link";
import { MdPhone } from "@react-icons/all-files/md/MdPhone";
import { MdSmartphone } from "@react-icons/all-files/md/MdSmartphone";
import { FiMail } from "react-icons/fi";
import Navigation from "../components/navigation";
import { v4 as uuidv4 } from "uuid";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const ContactUsPage = ({ data }) => {
	const {
		pageData: { seoFields, contactUsFields },
	} = data;

	const { contactBanner, contactDetailSection, contactLocation } =
		contactUsFields;

	const { siteUrl } = data.site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.metaTitle}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<section
				className="pb-5"
				style={{
					background:
						"transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
					opacity: 1,
				}}
			>
				<Navigation background="none" />
				<Container className="mt-4">
					<Row>
						<Col className="text-center my-5">
							<h1 className="text-primary display-4 jost-bold">
								{contactBanner?.contactBannerHeading || "Get in touch"}
							</h1>
						</Col>
					</Row>
					<Row>
						<Col lg={7}>
							<ContactForm />
						</Col>
						<Col className="ps-lg-6 mt-4 mt-lg-0" lg={5}>
							<h2 className="jost-bold mb-4">
								{contactDetailSection?.contactDetailHeading ||
									"Contact Details"}
							</h2>{" "}
							{contactDetailSection &&
								contactDetailSection.contactDetailEmail && (
									<Link
										className="email-link contact-link "
										to={`mailto:${contactDetailSection.contactDetailEmail}`}
									>
										<p className="fs-5 mb-4 email-link">
											{" "}
											<FiMail className="fs-4 email-link" />{" "}
											{contactDetailSection.contactDetailEmail}
										</p>
									</Link>
								)}
							{contactDetailSection &&
								contactDetailSection.contactDetailMobile && (
									<a
										className=" phone-number  contact-link phone-link align-items-center"
										href={`tel:+44${contactDetailSection.contactDetailMobile.replace(
											/\s/g,
											""
										)}`}
									>
										<p className="fs-5 phone-link">
											<MdSmartphone className="pl-1 fs-4 phone-link" /> 0
											{contactDetailSection.contactDetailMobile}
										</p>
									</a>
								)}
							{contactDetailSection &&
								contactDetailSection.contactDetailPhone && (
									<a
										className=" phone-number contact-link phone-link align-items-center"
										href={`tel:0${contactDetailSection.contactDetailPhone.replace(
											/\s/g,
											""
										)}`}
									>
										<p className="fs-5 phone-link">
											<MdPhone className="pl-1 fs-4 phone-link" /> 0
											{contactDetailSection.contactDetailPhone}
										</p>
									</a>
								)}
						</Col>
					</Row>
				</Container>
			</section>
			{contactLocation &&
				!checkPropertiesForNull(contactLocation, ["contactLocations"]) && (
					<section className="bg-contactBg py-5">
						<Container id="locations">
							<Row>
								<Col>
									<h3 className="jost-regular text-center mb-5">
										{contactLocation?.contactLocationHeading}
									</h3>
								</Col>
							</Row>
							{contactLocation.contactLocations &&
								contactLocation.contactLocations.length > 0 && (
									<Row className="gy-5">
										{contactLocation.contactLocations.map((item) => (
											<Col
												className="text-center d-flex flex-column justify-content-between"
												lg={6}
												key={uuidv4()}
											>
												<div className="mb-1">
													<SafeHtmlParser
														htmlContent={item.contactLocationAddress}
													/>
												</div>

												<iframe
													style={{ height: "50vh" }}
													src={item.contactLocationEmbeddedCode}
													frameBorder="0"
													aria-hidden="false"
													title="google-map"
													width="100%"
													loading="lazy"
												></iframe>
											</Col>
										))}
									</Row>
								)}
						</Container>
					</section>
				)}
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
		pageData: wpPage(slug: { eq: "contact-us" }) {
			contactUsFields {
				contactBanner {
					contactBannerHeading
				}
				contactDetailSection {
					contactDetailHeading
					contactDetailEmail
					contactDetailMobile
					contactDetailPhone
				}
				contactLocation {
					contactLocationHeading
					contactLocations {
						contactLocationAddress
						contactLocationEmbeddedCode
					}
				}
			}
			seoFields {
				metaTitle
				metaDescription
				opengraphTitle
				opengraphDescription
				productSchema
				image {
					node {
						altText
						publicUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
								original {
									width
									height
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default ContactUsPage;
