import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ContactForm() {
	const [state, handleSubmit] = useForm("meqwvekj");
	if (state.succeeded) {
		return (window.location = "/thank-you");
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="name">
				<Form.Control
					style={{ background: "none" }}
					name="name"
					placeholder="Name"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="email">
				<Form.Control
					style={{ background: "none" }}
					name="_replyto"
					type="email"
					placeholder="Email Address"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="mb-3" controlId="telephone">
				<Form.Control
					style={{ background: "none" }}
					type="tel"
					name="telephone"
					placeholder="Telephone Number"
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="message">
				<Form.Control
					style={{ background: "none" }}
					name="message"
					placeholder="Message"
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />
			<Form.Group className="mb-3" controlId="sign-up-for-newsletter">
				<Form.Check
					label="Subscribe me to the the regular newsletter"
					className="w-auto"
					type="checkbox"
					value="true"
					name="sign-up-for-newsletter"
				/>
			</Form.Group>
			<ValidationError
				prefix="sign-up-for-newsletter"
				field="sign-up-for-newsletter"
				errors={state.errors}
			/>

			{/* <div
				class="g-recaptcha"
				data-sitekey="6LcVT1IaAAAAAD_TjPg3NBiKMiBoLbTVdg7gnS5b"
			></div> */}
			<Button
				size="small"
				className="btn  btn-primary fs-5 border-0 bg-button px-4 mt-2"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Send Message
			</Button>
		</Form>
	);
}
export default ContactForm;
